<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :width="250" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <div v-if="winwidth>winwidthmodelimit&&!ismobile">
                <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <menu-fold-outlined v-else class="trigger" @click="switchmenu"></menu-fold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
            <div v-else>
                <menu-unfold-outlined class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        <a-card>
                            {{$langsmgr('langkey.component.myroutes.testtip')}}
                        </a-card>
                        <a-card :title="$langsmgr('langkey.component.myroutes.routeconfig')" :bordered="false" :size="ismobile?'small':'default'" class="rowcard"
                            :style="{borderRadius:'2px'}" :headStyle="{backgroundColor:'#f8f9fc'}"
                            :bodyStyle="{backgroundColor:'white',margin:0,padding:0}">
                            <template #extra>
                                <span style="margin-right:10px;">
                                    <a-button v-if="ispaiduser"  type="primary" size="small" @click="showCreateDialog">{{$langsmgr('langkey.component.myroutes.createroute')}}</a-button>
                                    <a-button v-else type="primary" size="small" @click="buysubscr" danger>
                                        {{$langsmgr('langkey.component.myroutes.buysubscr')}}
                                    </a-button>
                                </span>
                                <span style="float:left;">
                                    <a-button type="link" @click="documentShow('1090',$langsmgr('langkey.component.myroutes.aboutroute.title'))">
                                        {{$langsmgr('langkey.component.myroutes.aboutroute')}}
                                    </a-button>
                                </span>
                            </template>
                            <div v-if="userroutes.length <= 0x00">
                                <span style="padding:10px"><a-empty/></span>
                            </div>
                            <div v-else>
                                <div v-for="route in userroutes">
                                    <a href="javascript:;" class="docitem" @click="void(0)">
                                        <div style="float:right">
                                            <a-popconfirm v-if="route.enabled" :title="$langsmgr('langkey.component.myroutes.disableroute.confirmtext')" 
                                                :cancel-text="$langsmgr('langkey.component.myroutes.oproute.cancel')"
                                                :ok-text="$langsmgr('langkey.component.myroutes.disableroute.buttontext')" @confirm="disableRoute(route)">
                                                <a-tag color="#87d068" style="margin:0">{{$langsmgr('langkey.component.myroutes.enabledroute.buttontext')}}</a-tag>
                                            </a-popconfirm>
                                            <a-popconfirm v-else :title="$langsmgr('langkey.component.myroutes.enableroute.confirmtext')"
                                                :ok-text="$langsmgr('langkey.component.myroutes.enableroute.buttontext')" 
                                                :cancel-text="$langsmgr('langkey.component.myroutes.oproute.cancel')"
                                                @confirm="enableRoute(route)">
                                                <a-button size="small">{{$langsmgr('langkey.component.myroutes.enableroute.buttontext')}}</a-button>
                                            </a-popconfirm>
                                            <a-divider type="vertical" style="margin:5px"/>
                                            <a-button type="primary" size="small" @click="showCreateDialog(route)">
                                                {{$langsmgr('langkey.component.myroutes.config.buttontext')}}
                                            </a-button>
                                            <a-divider type="vertical" style="margin:5px"/>
                                            <a-popconfirm :title="$langsmgr('langkey.component.myroutes.deleteroute.confirmtext')" 
                                                :cancel-text="$langsmgr('langkey.component.myroutes.oproute.cancel')" placement="topRight"
                                                :ok-text="$langsmgr('langkey.component.myroutes.deleteroute.buttontext')" @confirm="deleteRoute(route)">
                                                <a-button type="primary" size="small" danger>{{$langsmgr('langkey.component.myroutes.deleteroute.buttontext')}}</a-button>
                                            </a-popconfirm>
                                        </div>
                                        <div class="docitemtit">{{route.domain}}</div>
                                        <div class="docitemdec">
                                            <span><a-tag :color="route.stacolor" style="margin:0">{{route.statext}}</a-tag></span>
                                            <a-divider type="vertical"/>
                                            <span>{{$langsmgr('langkey.component.document.tip.lasttime')}}{{route.updatetime}}</span>
                                        </div>
                                    </a>
                                    <a-divider style="margin:0" dashed/>
                                </div>
                            </div>
                        </a-card>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
            <div>
                <a-drawer width="80%" :title="document.title" placement="right" :closable="false" :open="documentshowstate" @close="documentClose">
                    <template #extra>
                        <CloseOutlined class="custclose" @click="documentClose"/>
                    </template>
                    <a-skeleton active :loading="document.loading">
                        <div v-html="document.content"></div>
                    </a-skeleton>
                </a-drawer>
            </div>
            <div>
                <a-modal v-model:visible="createdialog_visiable" :title="createdialog_title" :footer="null">
                    <a-row>
                        <a-col :span="4">
                            <span>{{$langsmgr('langkey.component.myroutes.module.text')}}</span>
                        </a-col>
                        <a-col :span="20">
                            <span>
                                <a-select value="cloudflare" :disabled="submitroute_loading">
                                    <a-select-option value="cloudflare">{{$langsmgr('langkey.component.myroutes.module.cloudflare')}}</a-select-option>
                                </a-select>
                            </span>
                            <span v-if="submitroute_loading" style="padding-left:10px">
                                <LoadingOutlined/>&nbsp;{{$langsmgr('langkey.component.myroutes.module.submit.validating')}}
                            </span>
                        </a-col>
                    </a-row>
                    <a-row style="height:10px"></a-row>
                    <a-row>
                        <a-col :span="4"></a-col>
                        <a-col :span="20">
                            <a-tag color="cyan">{{$langsmgr('langkey.component.myroutes.module.tips')}}</a-tag>
                        </a-col>
                    </a-row>
                    <a-row style="height:10px"></a-row>
                    <a-row>
                        <a-col :span="4">
                            <span>{{$langsmgr('langkey.component.myroutes.module.domain')}}</span>
                        </a-col>
                        <a-col :span="20">
                            <a-input-group compact>
                                <a-input v-model:value="routedata.domain" 
                                    :placeholder="$langsmgr('langkey.component.myroutes.module.domain.placeholder')"
                                    style="width: calc(100% - 75px)" 
                                    :disabled="submitroute_loading"
                                    :status="routedata.domainstatus"
                                    @change="validateDomain"></a-input>
                                <a-button>{{$langsmgr('langkey.component.myroutes.module.help')}}</a-button>
                            </a-input-group>
                        </a-col>
                    </a-row>
                    <a-row style="height:10px"></a-row>
                    <a-row>
                        <a-col :span="4">
                            <span>{{$langsmgr('langkey.component.myroutes.module.zoneid')}}</span>
                        </a-col>
                        <a-col :span="20">
                            <a-input-group compact>
                                <a-input v-model:value="routedata.regionid" 
                                    :placeholder="$langsmgr('langkey.component.myroutes.module.zoneid.placeholder')"
                                    style="width: calc(100% - 75px)"
                                    :disabled="submitroute_loading"
                                    :status="routedata.regionstatus"
                                    @change="validateRegionID"></a-input>
                                <a-button >{{$langsmgr('langkey.component.myroutes.module.help')}}</a-button>
                            </a-input-group>
                        </a-col>
                    </a-row>
                    <a-row style="height:10px"></a-row>
                    <a-row>
                        <a-col :span="4">
                            <span>{{$langsmgr('langkey.component.myroutes.module.apitoken')}}</span>
                        </a-col>
                        <a-col :span="20">
                            <a-input-group compact>
                                <a-input 
                                    v-model:value="routedata.apikey" 
                                    :placeholder="$langsmgr('langkey.component.myroutes.module.apitoken.placeholder')"
                                    style="width: calc(100% - 75px)" 
                                    :disabled="submitroute_loading"
                                    :status="routedata.apikeystatus"
                                    @change="validateCFApiKey"></a-input>
                                <a-button>{{$langsmgr('langkey.component.myroutes.module.help')}}</a-button>
                            </a-input-group>
                        </a-col>
                    </a-row>
                    <a-row style="height:20px"></a-row>
                    <a-row>
                        <a-col :span="14" style="text-align:left;">
                            <span style="color:#999">{{$langsmgr('langkey.component.myroutes.module.submit.tips')}}</span>
                        </a-col>
                        <a-col :span="10" style="text-align:right;">
                            <span><a-button type="primary" :loading="submitroute_loading" @click="submitNewRoute">{{createdialog_oktext}}</a-button></span>
                        </a-col>
                    </a-row>
                </a-modal>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,CloseOutlined,LoadingOutlined} from '@ant-design/icons-vue'
import { ref,getCurrentInstance, onMounted } from 'vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'
import {message} from 'ant-design-vue'
import useClipboard from 'vue-clipboard3'
import {Base64} from 'js-base64'

const currentviewlankey = ref('langkey.menuitem.myroutes')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)
const usubscrid = ref(null)

const loading_context = ref(true)
//setTimeout(()=>{loading_context.value=false},3000)

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties
const collapsed = ref(ginstance.appContext.config.globalProperties.$uimenucollapsed)
const ismobile = ref(ginstance.appContext.config.globalProperties.$ismobile())
const documentshowstate = ref(false)
const document = ref({
    loading: false,
    title: null,
    content: null
})
const {toClipboard} = useClipboard()

onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)
    loadmyroutes()
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) ginstance.appContext.config.globalProperties.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const submitroute_loading = ref(true)
const createdialog_visiable = ref(false)
const createdialog_title = ref('')
const createdialog_oktext = ref('')
const userroutes = ref([])
const ispaiduser = ref(false)
const routedata = ref({
    id: null,
    modid: null,
    domain: null,
    domainstatus: null,
    regionid: null,
    regionstatus: null,
    apikey: null,
    apikeystatus: null
})

const buysubscr = () => {
    location.href = "/#/plans"
}

const showCreateDialog = (route) => {
    submitroute_loading.value = false
    if(route){
        routedata.value.id = route.id
        routedata.value.domain = route.domain
        routedata.value.regionid = route.cfzoneid
        routedata.value.apikey = route.cfapikey
        createdialog_title.value = gconfig.$langsmgr("langkey.component.myroutes.module.submit.edit")
        createdialog_oktext.value = gconfig.$langsmgr("langkey.component.myroutes.module.submit.editensure")
    }else{
        routedata.value.id = null
        routedata.value.domain = null
        routedata.value.regionid = null
        routedata.value.apikey = null
        createdialog_title.value = gconfig.$langsmgr("langkey.component.myroutes.module.submit.create")
        createdialog_oktext.value = gconfig.$langsmgr("langkey.component.myroutes.module.submit.create")
    }
    createdialog_visiable.value = true
}
const closeCreateDialog = () => {
    createdialog_visiable.value = false
}
const submitNewRoute = () => {
    if(!__validateDomain(routedata.value.domain)){
        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.domainerror"))
        return
    }
    if(!__validateCFZoneID(routedata.value.regionid)){
        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.zoneiderror"))
        return
    }
    if(!__validateCFApiKey(routedata.value.apikey)){
        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.apitokenerror"))
        return
    }
    var __postdata = {
        id: routedata.value.id ? routedata.value.id : "",
        domain: routedata.value.domain,
        cfzoneid: routedata.value.regionid,
        cfapikey: routedata.value.apikey
    }
    submitroute_loading.value = true
    axios.post(`${gconfig.$backendbase}/usrctl/v3/${__postdata.id?'updateroute':'addroute'}`,__postdata,{
        params:{r:Math.random()},
        headers:gconfig.$getauthheaders(),
    }).then((response)=>{
        submitroute_loading.value = false
        if (response) {
            if(response.data.data.result){
                createdialog_visiable.value = false
                message.success(routedata.value.id?
                    gconfig.$langsmgr("langkey.component.myroutes.module.submit.success.update") :
                    gconfig.$langsmgr("langkey.component.myroutes.module.submit.success.create")
                    )
                loadmyroutes()
            }else{
                switch(response.data.code){
                    case "rsc/20005":
                        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.usererror"))
                        break
                    case "rsc/20033":
                        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.domainexisted"))
                        break
                    case "rsc/20034":
                        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.configerror"))
                        break;
                    case "rsc/20035":
                        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.routenotexist"))
                        break;
                }
            }
        }
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })
}
const enableRoute = (route) => {
    axios.get(`${gconfig.$backendbase}/usrctl/v3/enableroute`,{
        params:{
            id: route.id,
            r: Math.random()
        },
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        if(response){
            if(response.data.data.result){
                message.success(gconfig.$langsmgr("langkey.component.myroutes.module.submit.success.update"))
                loadmyroutes()
            }else{
                switch(response.data.data.code){
                    case 'rsc/20005':
                        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.usererror"))
                        break
                    case 'rsc/20033':
                        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.routenotexist"))
                        break
                }
            }
        }
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })
}
const disableRoute = (route) => {
    axios.get(`${gconfig.$backendbase}/usrctl/v3/disableroute`,{
        params:{
            id: route.id,
            r: Math.random()
        },
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        if(response){
            if(response.data.data.result){
                message.success(gconfig.$langsmgr("langkey.component.myroutes.module.submit.success.update"))
                loadmyroutes()
            }else{
                switch(response.data.data.code){
                    case 'rsc/20005':
                        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.usererror"))
                        break
                    case 'rsc/20033':
                        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.routenotexist"))
                        break
                }
            }
        }
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })
}

const deleteRoute = (route) => {
    axios.get(`${gconfig.$backendbase}/usrctl/v3/deleteroute`,{
        params:{
            id: route.id,
            r: Math.random()
        },
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        if(response){
            if(response.data.data.result){
                message.warning(gconfig.$langsmgr("langkey.component.myroutes.module.delete.tips"))
                loadmyroutes()
            }else{
                switch(response.data.data.code){
                    case 'rsc/20005':
                        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.usererror"))
                        break
                    case 'rsc/20033':
                        message.error(gconfig.$langsmgr("langkey.component.myroutes.module.submit.error.routenotexist"))
                        break
                }
            }
        }
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })
}
const validateDomain = () => {
    routedata.value.domainstatus = __validateDomain(routedata.value.domain) ? null : 'error'
}
const validateRegionID = () => {
    routedata.value.regionstatus = __validateCFZoneID(routedata.value.regionid) ? null : 'error'
}
const validateCFApiKey = () => {
    routedata.value.apikeystatus = __validateCFApiKey(routedata.value.apikey) ? null : 'error'
}
const __validateDomain = (str) => {
    return /^[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/.test(str)
}
const __validateCFZoneID = (str) => {
    return /^[a-z0-9]{32}$/.test(str)
}
const __validateCFApiKey = (str) => {
    return /^[a-zA-Z0-9\_\-]+$/.test(str)
}
const loadmyroutes = () => {
    axios.get(`${gconfig.$backendbase}/usrctl/v3/myroutes`,{
        params: { r: Math.random() },
        headers: gconfig.$getauthheaders()
    }).then((response)=>{
        userroutes.value = []
        if(response && response.data.data.result) {
            ispaiduser.value = true
            for(var i = 0x00; i < response.data.data.routes.length; i++){
                var __route = response.data.data.routes[i];
                userroutes.value.push({
                    id: __route.id,
                    enabled: __route.enabled,
                    type: __route.type,
                    priority: __route.priority,
                    domain: __route.rootdomain,
                    cfzoneid: __route.cfzoneid,
                    cfapikey: __route.cfapikey,
                    status: __route.status,
                    statext: __route.status == 0x00 ? gconfig.$langsmgr("langkey.component.myroutes.status.0x00") : 
                        __route.status == 0x01 ? gconfig.$langsmgr("langkey.component.myroutes.status.0x01") : 
                            __route.status == 0x02 ? gconfig.$langsmgr("langkey.component.myroutes.status.0x02") :
                                __route.status == -0x01 ? gconfig.$langsmgr("langkey.component.myroutes.status.-0x01") : 
                                gconfig.$langsmgr("langkey.component.myroutes.status.0x03"),
                    stacolor: __route.status == 0x00 ? '#ff6600' :
                        __route.status == 0x01 ? '#2db7f5' :
                            __route.status == 0x02 ? '#faad14' :
                                __route.status == -0x01 ? '#ff5500' : '#999999',
                    updatetime: __route.updatetime,
                    expires: __route.expires,
                    regtime: __route.regtime
                })
            }
        }else{
            switch(response.data.data.code){
                case "rsc/20036":
                case "rsc/20037":
                    ispaiduser.value = false;
                    break;
            }
        }
    }).catch((error)=>{
        gconfig.$axiosErrorHandle(error)
    })
}



const documentShow = (id,title) => {
    document.value.title = title
    document.value.loading = true
    axios.get(`/docs/${gconfig.$vlangcode}/${id}.cnt`)
        .then(resp=>{
            var __cnt = resp.data
            __cnt = __cnt.replace(/\{PROJNAME\}/g, gconfig.$sitename)
            __documentshow(title,__cnt)
        }).catch(err=>gconfig.$axiosErrorHandle(err))
}
const documentClose = () => { 
    document.value.title = null
    document.value.content = null
    documentshowstate.value = false
}

const __documentshow = (title,content) => {
    document.value.content = content
    document.value.loading = false
    documentshowstate.value = true
}

window.$importtoclash = async()=>{
    var uri = `${gconfig.$subscruribase}/v3/subscr?id=${usubscrid.value}`
    window.open(`clash://install-config?url=${encodeURIComponent(uri)}`,'_self')
}
window.$copymysubscraddr = async(data)=>{
    var subscrpath = `${gconfig.$subscruribase}/v3/subscr?id=${usubscrid.value}`
    try{ 
        await toClipboard(subscrpath)
        message.success('订阅地址复制成功')
    } catch { message.error('订阅地址复制失败') }
}
window.$gotocfagithub = () => {
    window.open('https://github.com/Kr328/ClashForAndroid/releases','_blank')
}
window.$gotocfwgithub = () => {
    window.open('https://github.com/Fndroid/clash_for_windows_pkg/releases', '_blank')
}
window.$gotoclashxithub = () => {
    window.open('https://github.com/yichengchen/clashX/releases', '_blank')
}
window.$importtoshadowrocket = async()=>{
    var uri = `${gconfig.$subscruribase}/v3/subscr?id=${usubscrid.value}`
    location.href = `shadowrocket://add/sub://${Base64.encode(uri)}/?remark=${encodeURIComponent(gconfig.$sitename)}`
}

</script>


<style src="./../assets/common.css" scoped></style>
<style scoped>

.docitem{
    display:block;
    margin:0;
    padding:15px 20px;
    text-align: left;
}
.docitem:hover{
    background-color: #f6f7fb;
}
.docintro{
    margin:0;
    padding:0;
}
.docitemtit {
    font-size:16px;
    font-weight: bold;
    color:#333333;
}
.docitemdec {
    padding-top: 8px;
    font-size:13px;
    color:#999999;
}
.custclose{
    cursor: pointer;
    color:#999999;
}
.custclose:hover{
    color:#666666;
}
</style>