<template>
    <a-dropdown :trigger="['click']">
        <a class="vmdropdown" @click.prevent>
            <TranslationOutlined/>&nbsp;
            {{ $currentlang() }}
        </a>
        <template #overlay>
            <a-menu>
                <div v-for="lang in $getsyslangs()">
                    <a-menu-item @click="setlang(lang.code)">
                        <a class="vmitem">{{lang.view}}</a>
                    </a-menu-item>
                    <a-menu-divider v-if="!lang.last"/>
                </div>
            </a-menu>
        </template>
    </a-dropdown>
</template>

<script setup>
import {TranslationOutlined} from '@ant-design/icons-vue'
import { getCurrentInstance,inject } from 'vue'

const reload = inject('reload')
const ginstance = getCurrentInstance()
const setlang=(langcode)=>{
    ginstance.appContext.config.globalProperties.$setsyslang(langcode);
    reload()
}

</script>

<style scoped>
.vmspan{
    padding-left: 15px;
}
.vmdivider{
    padding:0 2px;
}
.vmdropdown{
    cursor:pointer;
    font-size:14px;
    color:#999999;
}
.vmitem{
    padding-left: 10px;
}
</style>