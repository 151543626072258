<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :width="250" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <div v-if="winwidth>winwidthmodelimit&&!ismobile">
                <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <menu-fold-outlined v-else class="trigger" @click="switchmenu"></menu-fold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
            <div v-else>
                <menu-unfold-outlined class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null"
            :style="{marginTop:0}">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        <a-card class="rowcard" :style="{borderRadius:'2px',marginTop:0}" :bodyStyle="{padding:'20px'}" :bordered="false">
                            <div class="invitecard">
                                {{$langsmgr('langkey.component.promotion.title.myinvites')}}
                                <div class="inviteicon"><UserAddOutlined/></div>
                            </div>
                            <a-skeleton active :loading="loading_promodata" :style="{padding:'20px 0 0 0'}">
                                <div class="popwallet">
                                        <span class="balance">{{promotioninfo.popbalance}}</span>
                                        <span class="coinunit">CNY</span>
                                </div>
                                <div class="surplustext">
                                    {{$langsmgr('langkey.component.promotion.text.commission')}}
                                </div>
                                <div class="popwalletopts">
                                    <span>
                                        <a-button type="primary" :class="'fakebtn'" @click="openWithwalletModal">
                                            <TransactionOutlined/>
                                            {{$langsmgr('langkey.component.promotion.text.transfer')}}
                                        </a-button>
                                    </span>
                                    <span class="pl10px">
                                        <a-button @click="openWithdrawModal">
                                            <TransactionOutlined/>
                                            {{$langsmgr('langkey.component.promotion.text.withdraw')}}
                                        </a-button>
                                    </span>
                                </div>
                            </a-skeleton>
                        </a-card>
                        <a-card class="rowcard" :style="{borderRadius:'2px'}" :bodyStyle="{padding:'20px'}">
                            <a-skeleton active :loading="loading_promodata">
                                <a-row :style="{padding:'0',lineHeight:'30px'}">
                                    <a-col :span="12" class="dkey lartext">{{$langsmgr('langkey.component.promotion.text.registeredusers')}}</a-col>
                                    <a-col :span="12" class="dval lartext">
                                        {{promotioninfo.popusercount}}
                                    </a-col>
                                    <a-col :span="12" class="dkey lartext">{{$langsmgr('langkey.component.promotion.text.dividendpercent')}}</a-col>
                                    <a-col :span="12" class="dval lartext">
                                        {{promotioninfo.promopercent}} %
                                    </a-col>
                                    <a-col :span="12" class="dkey lartext">{{$langsmgr('langkey.component.promotion.text.dividendpercent.ensure')}}</a-col>
                                    <a-col :span="12" class="dval lartext">
                                        ¥ {{promotioninfo.ensureamount}}
                                    </a-col>
                                    <a-col :span="12" class="dkey lartext">{{$langsmgr('langkey.component.promotion.text.dividendpercent.total')}}</a-col>
                                    <a-col :span="12" class="dval lartext">
                                        ¥ {{promotioninfo.completedamount}}
                                    </a-col>
                                </a-row>
                            </a-skeleton>
                        </a-card>
                        <a-card class="rowcard" :title="$langsmgr('langkey.component.promotion.title.promocodemanager')" :style="{borderRadius:'2px'}" 
                            :headStyle="{backgroundColor:'#f8f9fc'}" :bodyStyle="{padding:0}">
                            <template #extra>
                                <a-button :loading="loading_geninvitecode" :class="'fakebtn'" @click="geninvitecode" type="primary">{{$langsmgr('langkey.component.promotion.text.promocode.gen')}}</a-button>
                            </template>
                            <a-row :style="{padding:'15px 20px'}">
                                <a-col :span="12" class="dkey">{{$langsmgr('langkey.component.promotion.text.column.promocode')}}</a-col>
                                <a-col v-if="promotioninfo.uweight>99" :span="2" class="dkey">{{$langsmgr('langkey.component.promotion.text.column.jumpin')}}</a-col>
                                <a-col v-if="promotioninfo.uweight>99" :span="2" class="dkey">{{$langsmgr('langkey.component.promotion.text.column.signup')}}</a-col>
                                <a-col v-if="promotioninfo.uweight>99" :span="2" class="dkey">{{$langsmgr('langkey.component.promotion.text.column.recharge')}}</a-col>
                                <a-col :span="promotioninfo.uweight>99?6:12" class="dval">{{$langsmgr('langkey.component.promotion.text.column.createtime')}}</a-col>
                            </a-row>
                            <a-divider :style="{margin:0}"/>
                            <a-skeleton active :loading="loading_afflinks" :style="{padding:'20px'}">
                                <div v-if="afflinksdata.length > 0x00" v-for="afflink in afflinksdata">
                                    <a-row :style="{padding:'15px 20px'}">
                                        <a-col :span="12" class="dkey">
                                            <span>{{afflink.linkid}}</span>
                                            <span class="copyinvitlink"><a href="javascript:;" @click="copyafflink(afflink)">{{$langsmgr('langkey.component.promotion.text.copylink')}}</a></span>
                                        </a-col>
                                        <a-col v-if="promotioninfo.uweight>99" :span="2" class="dkey">{{afflink.jumpincount}}</a-col>
                                        <a-col v-if="promotioninfo.uweight>99" :span="2" class="dkey">{{afflink.regcount}}</a-col>
                                        <a-col v-if="promotioninfo.uweight>99" :span="2" class="dkey">￥ {{afflink.rebates}}</a-col>
                                        <a-col :span="promotioninfo.uweight>99?6:12" class="dval">{{afflink.createtime}}</a-col>
                                    </a-row>
                                    <a-divider :style="{margin:0}"/>
                                </div>
                                <a-empty v-else :image="EmptyIcon"/>
                            </a-skeleton>
                        </a-card>
                        <a-card class="rowcard" :title="$langsmgr('langkey.component.promotion.title.commissionrecord')" :style="{borderRadius:'2px'}" 
                            :headStyle="{backgroundColor:'#f8f9fc'}" :bodyStyle="{padding:0}">
                            <a-table :columns="dividendscolumns" :data-source="dividendsdata" :pagination="false">
                                <template #bodyCell="{column,record}">
                                    <a-skeleton active :loading="loading_settles">
                                        <template v-if="column.key=='submittime'">
                                            {{record.submittime}}
                                        </template>
                                        <template v-if="column.key=='commission'">
                                            ￥ {{record.commission}}
                                        </template>
                                        <template v-if="column.key=='completedtime'">
                                            {{record.completedtime}}
                                        </template>
                                        <template v-if="column.key=='state'">
                                            <a-badge :color="record.stacolor"/>
                                            {{record.statext}}
                                        </template>
                                    </a-skeleton>
                                </template>
                            </a-table>
                        </a-card>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
            <div>
                <a-modal v-model:open="tranmodalstate" :title="$langsmgr('langkey.component.promotion.text.transfer.title')" :bodyStyle="{padding:'10px 0'}">
                    <template #footer>
                        <a-button @click="closeWithwalletModal">{{$langsmgr('langkey.component.promotion.text.transfer.cancel')}}</a-button>
                        <a-button type="primary" @click="submitWithwallet">{{$langsmgr('langkey.component.promotion.text.transfer.ensure')}}</a-button>
                    </template>
                    <a-alert :message="$langsmgr('langkey.component.promotion.text.transfer.tip',{'{0}':promotioninfo.withwalletlimit})" type="error" :style="{borderRadius:'2px',color:'#74290e'}" show-icon banner>
                        <template #icon><InfoCircleFilled/></template>
                    </a-alert>
                    <div>
                        <div class="amounttitle">{{$langsmgr('langkey.component.promotion.text.transfer.balance')}}</div>
                        <div><a-input :disabled="true" :value="promotioninfo.popbalance"/></div>
                    </div>
                    <div>
                        <div class="amounttitle">{{$langsmgr('langkey.component.promotion.text.transfer.amount')}}</div>
                        <div><a-input-number v-model:value="withwalletamount" :loading="loading_withwalletstate" :min="1" :max="100" prefix="￥" style="width:100%" :precision="2" /></div>
                    </div>
                </a-modal>
                <a-modal v-model:open="withdrawmodalstate" :title="$langsmgr('langkey.component.promotion.text.withdraw.title')" :bodyStyle="{padding:'0 0 20px 0'}">
                    <template #footer>
                        <a-button @click="closeWithdrawModal">{{$langsmgr('langkey.component.promotion.text.withdraw.cancel')}}</a-button>
                        <a-button :class="'fakebtn'" type="primary" @click="submitWithdraw">{{$langsmgr('langkey.component.promotion.text.withdraw.ensure')}}</a-button>
                    </template>
                    <a-alert :message="$langsmgr('langkey.component.promotion.text.withdraw.tip', {'{0}':promotioninfo.settletype, '{1}':promotioninfo.withdrawlimit})" 
                        type="info" :style="{margin:'20px 0 0 0', borderRadius:'2px',color:'#999999'}" show-icon banner>
                        <template #icon><InfoCircleFilled/></template>
                    </a-alert>
                    <div>
                        <div class="amounttitle">{{$langsmgr('langkey.component.promotion.text.withdraw.type')}}</div>
                        <div>
                            <a-select ref="accountselect" v-model:value="refval_settletype" style="width:100%">
                                <a-select-option value="0">USDT-TRC20</a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div>
                        <div class="amounttitle">{{$langsmgr('langkey.component.promotion.text.withdraw.amount')}}</div>
                        <div><a-input v-model:value="refval_settleaccount" :loading="loading_withdrawstate" style="width:100%" /></div>
                    </div>
                </a-modal>
            </div>
            <contextHolder/>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,UserAddOutlined,TransactionOutlined,InfoCircleFilled} from '@ant-design/icons-vue'
import { ref,getCurrentInstance, onMounted } from 'vue'
import { Modal,message,Empty } from 'ant-design-vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'
import useClipboard from 'vue-clipboard3'

const EmptyIcon = Empty.PRESENTED_IMAGE_SIMPLE

const {toClipboard} = useClipboard()

const [modal, contextHolder] = Modal.useModal()

const currentviewlankey = ref('langkey.menuitem.affplan')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)
const tranmodalstate = ref(false)
const withdrawmodalstate = ref(false)

const withwalletamount = ref(1)
const refval_settletype = ref('')
const refval_settleaccount = ref('')

const loading_promodata = ref(true)
const loading_withwalletstate = ref(false)
const loading_withdrawstate = ref(false)
const loading_settles = ref(false)
const loading_afflinks = ref(true)
const loading_geninvitecode = ref(false)

const promotioninfo = ref({
    uweight: null,
    popbalance: null,
    popusercount: null,
    promopercent: null,
    ensureamount: null,
    completedamount: null,
    withdrawlimit: null,
    withwalletlimit: null,
    settletype: null
})

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties
const collapsed = ref(ginstance.appContext.config.globalProperties.$uimenucollapsed)
const ismobile = ref(ginstance.appContext.config.globalProperties.$ismobile())

const dividendscolumns = [
    {title:gconfig.$langsmgr('langkey.component.promotion.text.column.submittime'),name:"submittime",width:200,dataIndex:"submittime",key:"submittime"},
    {title:gconfig.$langsmgr('langkey.component.promotion.text.column.commission'),name:"commission",dataIndex:"commission",key:"commission"},
    {title:gconfig.$langsmgr('langkey.component.promotion.text.column.settletime'),name:"completedtime",width:200,dataIndex:"completedtime",key:"completedtime"},
    {title:gconfig.$langsmgr('langkey.component.promotion.text.column.state'),name:"state", width:120, dataIndex:"state",key:"state", fixed: "right",align:"right"},
]
const dividendsdata = ref([
    {completedtime:"-", state: 0, commission:"-"}
])

const afflinksdata = ref([{
    linkid: null,
    link: null,
    createtime: null
}])

onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)

    loaduserpromotioninfo()
    loadafflinksdata()
    loadsettles()
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) ginstance.appContext.config.globalProperties.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const openWithwalletModal = () => {
    tranmodalstate.value = true
}
const closeWithwalletModal = () => {
    tranmodalstate.value = false
}
const submitWithwallet = () => {
    if(withwalletamount.value<=0){
        message.error(gconfig.$langsmgr('langkey.component.promotion.text.transfer.error'))
        return
    }
    loading_withwalletstate.value=true
    axios.get(`${gconfig.$backendbase}/promotion/v3/promotionblanacewithwallet`,{
        params: {
            amount: withwalletamount.value,
            r:Math.random()
        },
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        if(resp.data.data.result) {
            closeWithwalletModal()
            setTimeout(()=>{
                loaduserpromotioninfo()
            },500)
        }else{
            message.error(gconfig.$langsmgr('langkey.component.promotion.text.transfer.fail.lessthanbalance'))
        }
        loading_withwalletstate.value=false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}
const openWithdrawModal = () => {
    withdrawmodalstate.value = true
}
const closeWithdrawModal = () => {
    withdrawmodalstate.value = false
}
const submitWithdraw = () => {
    if(!refval_settleaccount.value||refval_settleaccount.value.length==0x00){
        message.error(gconfig.$langsmgr('langkey.component.promotion.text.withdraw.submit.fail.accountnotfilled'))
        return
    }
    //console.log(promotioninfo.value.popbalance, promotioninfo.value.withdrawlimit)
    if(parseFloat(promotioninfo.value.popbalance) < parseFloat(promotioninfo.value.withdrawlimit)){
        message.error(gconfig.$langsmgr('langkey.component.promotion.text.withdraw.submit.fail.lessthanbalance'))
        return
    }
    loading_withdrawstate.value=true
    axios.get(`${gconfig.$backendbase}/aff/v3/submitaffsettle`,{
        params: {
            type: refval_settletype.value,
            account: refval_settleaccount.value
        },
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        //console.log(resp)
        if(resp.data.data.result){
            closeWithdrawModal()
            setTimeout(()=>{
                loaduserpromotioninfo()
            },500)
        }else{
            switch(resp.data.data.code){
                case "rsc/20025":
                    message.error(gconfig.$langsmgr('langkey.component.promotion.text.withdraw.submit.fail.notaffman'))
                    break;
            }
        }
        loading_withdrawstate.value=false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}
const loaduserpromotioninfo = () => {
    loading_promodata.value = true
    axios.get(`${gconfig.$backendbase}/promotion/v3/getpromotioninfo`,{
        params:{r:Math.random()},
        headers: gconfig.$getauthheaders()
    }).then(resp => {
        //console.log(resp)
        if(resp.data.data.result) {
            promotioninfo.value.uweight = resp.data.data.userservweight
            promotioninfo.value.popbalance = resp.data.data.promoblanace.toFixed(0x02)
            promotioninfo.value.popusercount = resp.data.data.promocount
            promotioninfo.value.promopercent = resp.data.data.dividendspercent
            promotioninfo.value.ensureamount = resp.data.data.settleamount
            promotioninfo.value.completedamount = resp.data.data.promototalamount
            promotioninfo.value.withdrawlimit = resp.data.data.withdrawlimit.toFixed(0x02)
            promotioninfo.value.withwalletlimit = resp.data.data.withwalletlimit.toFixed(0x02)
            promotioninfo.value.settletype = resp.data.data.settletype
        }
        loading_promodata.value = false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}
const loadsettles = () => {
    dividendsdata.value.splice(0x00,dividendsdata.value.length)
    dividendsdata.value.push({
        submittime: '-',
        commission: 0,
        completedtime: '-',
        state: '-'
    })
    loading_settles.value=true
    axios.get(`${gconfig.$backendbase}/aff/v3/affsettlesinquire`,{
        params: {
            pageindex:1,
            r:Math.random()
        },
        headers:gconfig.$getauthheaders()
    }).then(resp=>{
        //console.log(resp)
        if(resp.data.data.result){
            dividendsdata.value.splice(0x00,dividendsdata.value.length)
            for(var i = 0x00; i < resp.data.data.settles.length; i++){
                var __settle = resp.data.data.settles[i]
                dividendsdata.value.push({
                    submittime: __settle.regtime,
                    commission: __settle.amount,
                    completedtime: __settle.updatetime,
                    state: __settle.status,
                    stacolor: __settle.status == 0x00 ? "blue" :
                        __settle.status == 0x01 ? "green" : "red",
                    statext: __settle.status == 0x00 ? gconfig.$langsmgr('langkey.component.promotion.text.state.review') :
                        __settle.status == 0x01 ? gconfig.$langsmgr('langkey.component.promotion.text.state.settle') :
                            gconfig.$langsmgr('langkey.component.promotion.text.state.cancel')
                })
            }
        }
        loading_settles.value = false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}

const loadafflinksdata = () => {
    loading_afflinks.value = true
    afflinksdata.value.splice(0x00,afflinksdata.value.length)
    afflinksdata.value.push({
        linkid: '-',
        link:'-',
        createtime: '-'
    })
    axios.get(`${gconfig.$backendbase}/aff/v3/loadafflinks`,{
        params:{r:Math.random()},
        headers:gconfig.$getauthheaders()
    }).then(resp=>{
        if(resp.data.data.result){
            afflinksdata.value.splice(0x00,afflinksdata.value.length)
            for(var i = 0x00; i < resp.data.data.afflinks.length;i++){
                var __afflink = resp.data.data.afflinks[i]
                afflinksdata.value.push({
                    linkid: __afflink.linkid,
                    link: __afflink.link,
                    regcount: __afflink.totalregs,
                    jumpincount: __afflink.totaljumpins,
                    rebates: __afflink.totalrebates.toFixed(0x02),
                    createtime: __afflink.regtime
                })
            }
        }
        loading_afflinks.value=false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}

const copyafflink = async(afflink) => {
    try{
        await toClipboard(afflink.link)
        message.success(gconfig.$langsmgr('langkey.component.promotion.text.copyafflink.success'))
    }catch{message.error(gconfig.$langsmgr('langkey.component.promotion.text.copyafflink.fail'))}
}

const geninvitecode = () => {
    loading_geninvitecode.value = true
    axios.get(`${gconfig.$backendbase}/aff/v3/createafflink`,{
        params:{r:Math.random()},
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        if(resp.data.data.result){
            message.success(gconfig.$langsmgr('langkey.component.promotion.text.promocode.gen.success'))
            loadafflinksdata()
        }else{
            switch(resp.data.data.code){
                case "rsc/20026":
                    message.error(gconfig.$langsmgr('langkey.component.promotion.text.promocode.gen.unlimit'))
                    break
                case "rsc/20004":
                    message.error(gconfig.$langsmgr('langkey.component.promotion.text.promocode.gen.loginerror'))
                    break
                default:
                    message.error(gconfig.$langsmgr('langkey.component.promotion.text.promocode.gen.unknowerror'))
                    break
            }
        }
        loading_geninvitecode.value = false
    }).catch(err=>{
        gconfig.$axiosErrorHandle(err)
    })
}

</script>

<style src="./../assets/common.css" scoped></style>
<style scoped>
.invitecard{
    position: relative;
    width:100%;
    font-size: 16px;
    color:#666666;
}
.inviteicon{
    position: absolute;
    right:-5px;
    top:-10px;
    font-size: 40px;
    color:#cccccc;
}
.popwallet{
    padding-top:20px;
}
.balance{
    font-size:60px;
    font-weight: 100;
}
.coinunit{
    padding-left:30px;
    font-size: 20px;
    font-weight: 100;
    color:#999999;
}
.surplustext{
    font-size: 16px;
    color:#666666;
}
.textright{
    text-align: right;
}
.popwalletopts{
    padding:15px 0 10px 0;
}
.pl10px{
    padding-left:10px;
}
.lartext{
    font-size:16px;
    color:#666666;
}
.dkey{
    text-align: left;
}
.dval{
    text-align: right;
}
.copyinvitlink{
    padding-left:10px;
}
.amounttitle{
    padding-top: 10px;
    color:#666666;
    line-height:40px;
    font-weight: bold;
}
</style>