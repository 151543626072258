<template>
  <div>
    <component :is="currentView" v-if="isRouteAlive"/>
  </div>
</template>

<script setup>    
import { ref, computed } from 'vue'

import login from './components/login.vue'
import register from './components/register.vue'
import repasswd from './components/repasswd.vue'
import dashboard from './components/dashboard.vue'
import profile from './components/profile.vue'
import billing from './components/billing.vue'
import billings from './components/billings.vue'
import financess from './components/financess.vue'
import flowstats from './components/flowstats.vue'
import services from './components/services.vue'
import packages from './components/packages.vue'
import recharge from './components/recharge.vue'
import activities from './components/activities.vue'
import tickets from './components/tickets.vue'
import tos from './components/tos.vue'
import documents from './components/documents.vue'
import invite from './components/invite.vue'
import ticket from './components/ticket.vue'
import myroutes from './components/myroutes.vue'

import notfound from './components/notfound.vue'

const routes = {
    '/': login,
    '/login': login,
    '/register': register,
    '/repasswd': repasswd,
    '/dashboard': dashboard,
    '/profile': profile,
    '/orders': billings,
    '/order': billing,
    '/financess': financess,
    '/flowstats': flowstats,
    '/services': services,
    '/plans': packages,
    '/plan': recharge,
    '/activities': activities,
    '/ticket': ticket,
    '/tickets': tickets,
    '/tos': tos,
    '/documents': documents,
    '/invite': invite,
    '/myroutes': myroutes
}
const currentPath = ref(window.location.hash)
window.addEventListener('hashchange', ()=>{
    currentPath.value = window.location.hash.split('?',0x02)[0x00]
})
window.addEventListener('load', ()=>{
    currentPath.value = window.location.hash.split('?',0x02)[0x00]
})
const currentView = computed(()=>{
    return routes[currentPath.value.slice(1) || '/'] || notfound
})



</script>
<script>
export default {
  provide(){
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      contentmode: 0x00,
      isRouteAlive: true
    }
  },
  methods: {
    reload(){
      this.isRouteAlive = false
      this.$nextTick(()=>{
        this.isRouteAlive = true
      })
    }
  }
}
</script>

<style>

#app {
  font-family: Inter,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
