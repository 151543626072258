<template>
    <div ref="comproot">
        <div v-if="antidetectloading==0x00">
            <a class="subscritem" href="javascript:;" @click="copysubscr">
                <span class="subscricon"><CopyOutlined/></span>
                <span>{{$langsmgr('langkey.component.dashboard.subscribe.copylink')}}</span>
            </a>
            <a-divider :style="{margin:0}"/>
            <a class="subscritem" href="javascript:;" @click="showqrcode">
                <span class="subscricon"><QrcodeOutlined/></span>
                <span>{{$langsmgr('langkey.component.dashboard.subscribe.scanqrcode')}}</span>
            </a>
            <a-divider :style="{margin:0}"/>
            <a v-if="isIOS" class="subscritem" href="javascript:;" @click="importshadowrocket">
                <span class="subscrsr">{{$langsmgr('langkey.component.dashboard.subscribe.importshadowrocket')}}</span>
            </a>
            <a v-else class="subscritem" href="javascript:;" @click="importcfw">
                <span class="subscrcfw">{{$langsmgr('langkey.component.dashboard.subscribe.importcfw')}}</span>
            </a>
            <a-divider :style="{margin:0}"/>
            <a class="subscritem" href="javascript:;" @click="downloadconfig">
                <span class="subscricon"><VerticalAlignBottomOutlined/></span>
                <span>{{$langsmgr('langkey.component.dashboard.subscribe.downloadconfig')}}</span>
            </a>
            <div class="subscrviewdoc">
                <a-button :block="true" type="primary" size="large" @click="viewdocument">{{$langsmgr('langkey.component.dashboard.subscribe.helpviewdocument')}}</a-button>
            </div>
            <a-divider :style="{margin:0}"/>
            <div class="subscrviewdoc">
                <a-button :block="true" class="selbtn" type="primary" size="large" @click="detectroutes">{{$langsmgr('langkey.component.dashboard.subscribe.selectroutes')}}</a-button>
            </div>
        </div>
        <div v-if="antidetectloading==0x01" class="antiblockloading">
            <div class="antiview">
                {{$langsmgr('langkey.component.dashboard.subscribe.tipviewtext')}}
            </div>
            <div class="antiload">
                <a-spin :tip="$langsmgr('langkey.component.dashboard.subscribe.tiploadtext')"></a-spin>
            </div>
        </div>
        <div v-if="antidetectloading==0x04">
            <div class="attext">
                <ExclamationCircleFilled :style="{color:'orange'}"/>
                {{$langsmgr("langkey.component.dashboard.subscribe.routestip")}}
            </div>
            <a-divider :style="{margin:'15px 0 2px 0'}"/>
            <a-spin :tip="routeloadingtext" :spinning="routechanging">
                <div>
                    <a class="subscritem" href="javascript:;" @click="selectroute(null)">
                        <span v-if="defaultrouteused" class="chkroute"><CheckCircleFilled :style="{color:'#87d068'}"/></span>
                        <span class="routext">{{$langsmgr('langkey.component.dashboard.subscribe.defaultroute')}}</span>
                    </a>
                </div>
                <div>
                    <div v-for="route in antiroutes">
                        <a-divider :style="{margin:0}"/>
                        <a class="subscritem" href="javascript:;" @click="selectroute(route)">
                            <span v-if="!defaultrouteused && route.used" class="chkroute"><CheckCircleFilled :style="{color:'#87d068'}"/></span>
                            <span class="routext">{{route.name}}</span>
                        </a>
                    </div>
                </div>
            </a-spin>
            <div class="antiback">
                <a-button :block="true" type="primary" size="large" @click="returnback">{{$langsmgr('langkey.component.dashboard.subscribe.goback')}}</a-button>
            </div>
        </div>
        <div v-if="antidetectloading==0x02">
            <div class="antiicon">
                <CheckCircleFilled :style="{color:'#87d068'}"/>
            </div>
            <div class="antiview">
                {{$langsmgr('langkey.component.dashboard.subscribe.routeenabled')}}
            </div>
            <div class="antiback">
                <a-button :block="true" type="primary" size="large" @click="returnback">{{$langsmgr('langkey.component.dashboard.subscribe.goback')}}</a-button>
            </div>
        </div>
        <div v-if="antidetectloading==0x03">
            <div class="antiicon">
                <CloseCircleFilled :style="{color:'#f50'}"/>
            </div>
            <div class="antiview">
                {{$langsmgr('langkey.component.dashboard.subscribe.routeenablefailed')}}
            </div>
            <div class="antiback">
                <a-button :block="true" type="primary" size="large" @click="returnback">{{$langsmgr('langkey.component.dashboard.subscribe.goback')}}</a-button>
            </div>
        </div>
    </div>
</template>
<script setup>
import {CopyOutlined,QrcodeOutlined,CheckOutlined,CheckCircleOutlined,CloseCircleOutlined,CloseCircleFilled,CheckCircleFilled,ExclamationCircleFilled,VerticalAlignBottomOutlined} from '@ant-design/icons-vue'
import {notification} from 'ant-design-vue'
import {getCurrentInstance,ref,h,onMounted,onActivated,onUpdated} from 'vue'
import axios from 'axios'

const comproot = ref()
const defaultheight = ref(0)
const antidetectloading = ref(0x00)
const antidetectinvalid = ref([])
const currentantidomain = ref('')
const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties
const antiroutes = ref([])
const defaultrouteused = ref(true)
const routechanging = ref(false)
const routeloadingtext = ref(gconfig.$langsmgr("langkey.component.dashboard.subscribe.routeenabling"))
const isIOS = ref(!!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X.*/))

//subscribeid.value = gconfig.$getusubscrid()
//__temp_copycallback = this.copycallback


const copysubscr = ()=> {
    emits('copycallback')
}
const showqrcode = () => {
    emits('qrcodecallback')
}
const importcfw=()=> {
    emits('importcfwcallback')
}
const importshadowrocket=()=>{
    emits('importshadowrocketcallback')
}
const viewdocument=()=> {
    emits('viewdoccallback')
}
const returnback=()=>{
    antidetectloading.value = 0x00
    emits('onchangeroute', 0x00)
}
const detectroutes=()=>{
    antidetectloading.value = 0x04
    //detecting()
    loadvalidroutes()
}
// const detecting = () => {
//     axios.post(`${gconfig.$backendbase}/antictl/v3/getvalidroute`,{
//         ded: antidetectinvalid.value
//     },{
//         params:{ r: Math.random() },
//         headers: gconfig.$getauthheaders()
//     }).then(resp=>{
//         if(resp.data.data.result) {
//             currentantidomain.value = resp.data.data.antidomain
//             axios.get(`${gconfig.$antiprotocol}://${currentantidomain.value}/i/${gconfig.$getuserid()}/${resp.data.data.hash}`,{
//                 params: {r:Math.random()},
//                 timeout: 10000,
//             }).then(ret=>{
//                 if(ret.data.data){
//                     notification.open({
//                         message: "已检测到可用线路",
//                         description: "正在为您启用可用线路，请稍候...",
//                         icon: () => h(CheckCircleOutlined,{style:'color:#87d068'}),
//                         duration:1
//                     })
//                     axios.post(`${gconfig.$backendbase}/antictl/v3/enableroute`,{
//                         routedomain: currentantidomain.value
//                     },{
//                         params: {r:Math.random()},
//                         headers: gconfig.$getauthheaders()
//                     }).then(er=>{
//                         if(er.data.data){
//                             antidetectinvalid.value.splice(0x00,antidetectinvalid.value.length)
//                             antidetectloading.value = 0x02
//                             notification.open({
//                                 message: "已启用可用线路",
//                                 description: "已为您启用有效的可用线路，请在5分钟后更新您的订阅数据。若仍然存在问题，可继续尝试本线路检测程序",
//                                 icon: () => h(CheckCircleOutlined,{style:'color:#87d068'})
//                             })
//                         }else{
//                             antidetectinvalid.value.splice(0x00,antidetectinvalid.value.length)
//                             antidetectloading.value = 0x03
//                             notification.open({
//                                 message: "可用线路启用失败",
//                                 description: "遭遇不可知的网络波动，请尝试重新线路检测。",
//                                 icon: () => h(CloseCircleOutlined,{style:'color:#f50'})
//                             })
//                         }
//                     }).catch(ee=>{
//                         antidetectinvalid.value.splice(0x00,antidetectinvalid.value.length)
//                         antidetectloading.value = 0x03
//                         notification.open({
//                             message: "当前线路检测失败",
//                             description: "遭遇不可知的网络波动，请尝试重新线路检测。",
//                             icon: () => h(CloseCircleOutlined,{style:'color:#f50'})
//                         })
//                     })
//                 }
//             }).catch(err=>{
//                 antidetectinvalid.value.push(currentantidomain.value)
//                 detecting()
//             })
//         }else{
//             antidetectinvalid.value.splice(0x00,antidetectinvalid.value.length)
//             antidetectloading.value = 0x03
//             notification.open({
//                 message: "未找到可用线路",
//                 description: "已查询所有线路，但未找到与您现有网络匹配的可用线路，可尝试与管理员沟通增加可用线路。",
//                 icon: () => h(CloseCircleOutlined,{style:'color:#f50'})
//             })
//         }
//     }).catch(err=>{
//         antidetectinvalid.value.splice(0x00,antidetectinvalid.value.length)
//         antidetectloading.value = 0x03
//         notification.open({
//             message: "获取线路失败",
//             description: "遭遇不可知的网络波动，请尝试重新线路检测。",
//             icon: () => h(CloseCircleOutlined,{style:'color:#f50'})
//         })
//     })
// }
const loadvalidroutes = () => {
    routeloadingtext.value = gconfig.$langsmgr("langkey.component.dashboard.subscribe.routeloading")
    routechanging.value = true
    axios.get(`${gconfig.$backendbase}/antictl/v3/getroutes`,{
        params: {r:Math.random()},
        headers: gconfig.$getauthheaders()
    }).then(resp=>{
        if(antiroutes.value.length>0x00)
            antiroutes.value.splice(0x00, antiroutes.value.length)
        if(resp.data.data.result){
            for(var i = 0x00; i < resp.data.data.routes.length;i++){
                var route = resp.data.data.routes[i]
                var __localroute = {
                    id: route.id,
                    name: route.name,
                    domain: route.domain,
                    used: route.used,
                    ispud: route.ispud,
                    isrule: route.isrule
                }
                __localroute.name = __localroute.ispud ? `私有路由（${__localroute.domain}）`:
                    __localroute.isrule ? __localroute.name :
                    `${gconfig.$langsmgr("langkey.component.dashboard.subscribe.backuptext")} ${(i+0x01)}`
                antiroutes.value.push(__localroute)
                if(route.used)
                    defaultrouteused.value = false
            }
        }
        
        emits('onchangeroute', antiroutes.value.length+0x01)
        routechanging.value = false
    }).catch(err=>{
        routechanging.value = false
        gconfig.$axiosErrorHandle(err)
    })
}
const selectroute = (route) => {
    routeloadingtext.value = gconfig.$langsmgr("langkey.component.dashboard.subscribe.routeenabling")
    routechanging.value = true
    if(route){
        axios.post(`${gconfig.$backendbase}/antictl/v3/enableuselectedroute`,{
            id: route.id,
            ispud: route.ispud
        },{
            params: {r:Math.random()},
            headers: gconfig.$getauthheaders()
        }).then(resp=>{
            defaultrouteused.value = true
            routechanging.value = false
            if(resp.data.data){
                defaultrouteused.value = false
                for(var i = 0x00; i < antiroutes.value.length; i++)
                    antiroutes.value[i].used = false
                route.used = true
            }
        }).catch(err=>{
            routechanging.value = false
            gconfig.$axiosErrorHandle(err)
        })
    }else{
        axios.get(`${gconfig.$backendbase}/antictl/v3/disableroute`,{
            params: {r:Math.random()},
            headers: gconfig.$getauthheaders()
        }).then(resp=>{
            routechanging.value = false
            if(result.data.data){
                defaultrouteused.value = true
                for(var i = 0x00; i < antiroutes.value.length; i++)
                    antiroutes.value[i].used = false
            }
        }).catch(err=>{
            routechanging.value = false
            gconfig.$axiosErrorHandle(err)
        })
    }

    defaultrouteused.value = route ? false : true
    if(route){
        for(var i = 0x00; i < antiroutes.value.length; i++)
            antiroutes.value[i].used = false
        route.used = true
    }

}
const downloadconfig = () => {
    emits('downloadconfigcallback')
}

const emits = defineEmits([
    'copycallback',
    'qrcodecallback',
    'importcfwcallback',
    'importshadowrocketcallback',
    'viewdoccallback',
    'onchangeroute',
    'downloadconfigcallback'
])

</script>


<style scoped>
.subscritem {
    display:block;
    height: 50px;
    line-height:50px;
    text-align: left;
}
.subscritem:hover {
    background-color: #f0f3f8;
}
.subscritem span {
    float:left;
    height: 50px;
    line-height:50px;
    margin:0 5px 0 10px;
    color:#666666;
}
.subscricon {
    font-size:25px;
}
.subscrcfw {
    padding: 0 0 0 55px;
    float:left;
    height: 50px;
    background-image: url(../assets/cfw.png);
    background-position: 11px 11px;
    background-size: 28px 28px;
    background-repeat: no-repeat;
}
.subscrsr {
    padding: 0 0 0 55px;
    float:left;
    height: 50px;
    background-image: url(../assets/sr.png);
    background-position: 11px 11px;
    background-size: 28px 28px;
    background-repeat: no-repeat;
}
.subscrviewdoc {
    padding: 10px 0 0 0;
}
.antiblockloading{
    text-align: center;
}
.antiview{
    color:#999;
    padding: 10px auto 20px auto;
}
.antiload{
    margin-top:20px;
}
.antiback{
    margin-top:10px;
}
.antiicon{
    text-align: center;
    font-size: 72px;
    padding-bottom: 10px;
}
.selbtn{
    background-color:#87d068ff;
}
.selbtn:hover{
    background-color:#87d068cc;
}
.chkroute{
    font-size:20px;
    font-weight: 900;
    color:#87d068ff;
}
.attext{
    color:#999;
}
.routext{
    font-size: 14px;
    font-weight: bold;
}
</style>